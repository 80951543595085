// Supabase constants:
const SUPABASE_URL = 'https://derclltpzfohoecoxvge.supabase.co';
const SUPABASE_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRlcmNsbHRwemZvaG9lY294dmdlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY0NDU2NjIsImV4cCI6MjAyMjAyMTY2Mn0.EDVPLpVX82RLxYlSv7ekIxcuXAP_fmEkzhOh67oXECk';

// Data base table names
const USER_PROFILE_TABLE = 'dashwave_users';
const PROJECTS_TABLE = 'dashwave_projects';

// Export the constants
export { SUPABASE_URL, SUPABASE_KEY, USER_PROFILE_TABLE, PROJECTS_TABLE };
