// ~~~~~~ Hostinger Icon ~~~~~~~
// Svg icon for the hostinger icon.
export function HostingerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="7.002 8.287 148.203 175.426"
    >
      <path
        fill="#6747c7"
        d="M7.002 8.287l39.319 21.172v39.32h57.467l36.295 21.172H7.002zm148.203 75.615V29.459L112.861 8.287v51.418zm0 99.811l-39.319-21.172v-39.32H58.419l-36.295-21.172h133.081zM7.002 108.098v54.443l42.343 21.172v-51.418z"
      ></path>
    </svg>
  );
}
